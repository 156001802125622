import React from "react";
import styled, { css } from "styled-components";

export function Title({ children, centered }) {
  return (
    <>
      <StyledTitle center={centered}>{children}</StyledTitle>
      <Hr center={centered} />
    </>
  );
}

const StyledTitle = styled.h2`
  font-size: 2.2em;
  line-height: 1.2;
  word-spacing: 1px;
  font-weight: 700;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`;

const Hr = styled.hr`
  margin: 2.2rem 0;

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
`;
