import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import styled from "styled-components";
import { Quote } from "../components/Quote";

export default function DataPage() {
  const data = useStaticQuery(
    graphql`
      query {
        allMongodbTakiwaWarehouseDatasetStructures(
          filter: {
            datasetType: { in: ["GEOJSON", "GEODATA"] }
            showInStaticDataCatalogue: { eq: true }
            deleted: { ne: true }
          }
          sort: { fields: datasetLabel, order: ASC }
        ) {
          edges {
            node {
              id
              datasetLabel
            }
          }
        }
      }
    `
  );

  const datasets = data.allMongodbTakiwaWarehouseDatasetStructures.edges;

  return (
    <Layout>
      <Page
        pageTitle="Data Catalogue"
        pageDescription="Bring your own data into the powerful Takiwā Platform, or use any of the numerous datasets available in the Takiwā Data Catalogue. You keep full control over your data."
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_blue_globe.jpg"
        }
      >
        <Title>Data Catalogue</Title>
        <Quote>
          <p>
            Bring your own data into the powerful Takiwā Platform, or use any of
            the numerous datasets available in the Takiwā Data Catalogue. You
            keep full control over your data.
          </p>
        </Quote>
        <h3>Available Datasets</h3>
        <ListWrapper>
          <ul>
            {datasets.map(({ node }) => {
              return (
                <li key={node.id}>
                  <Link
                    to={`/data/${node.id}`}
                    state={{ navigatedFromDatasetList: true }}
                  >
                    {node.datasetLabel}
                  </Link>
                </li>
              );
            })}
          </ul>
        </ListWrapper>
      </Page>
    </Layout>
  );
}

const ListWrapper = styled.div`
  ul {
    margin: 0 !important;
    list-style: none;
    color: red;
  }
`;
