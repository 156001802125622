import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";

export function Quote({ children, light }) {
  return <StyledBlockquote light={light}>{children}</StyledBlockquote>;
}

const StyledBlockquote = styled.blockquote`
  font-size: 1.3rem;
  padding: 1rem 1.5rem;
  border-radius: 0 ${props => props.theme.radius.small}
    ${props => props.theme.radius.small} 0;
  background-color: ${props =>
    props.light
      ? "#FFFFFF"
      : transparentize(0.95, props.theme.color.foreground)};
  color: ${props => (props.light ? props.theme.color.background : "inherit")};
  box-shadow: -6px 0 0 ${props => props.theme.color.primary};
  margin-left: 6px;
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
  *:last-child {
    margin-bottom: 0 !important;
  }
`;
